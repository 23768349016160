* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

header.header {
  display: flex;
  align-items: center;
  z-index: 99;
  padding-top: 56px;
  width: 100%;
  padding: 10px;
  background: #222f3f;
}

header.header .is-logo {
  float: left;
  margin-right: 12px;
}

header.header .logo {
  display: block;
}

aside,
.results-wrapper {
  padding: 10px;
}

.thank-you {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

.ais-Panel {
  margin-top: 20px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  color: white;
  font-size: 20px;
}

.results-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.sort-by {
  display: flex;
  align-items: center;
}

.sort-by label {
  margin-right: 5px;
}

footer {
  margin-top: 25px;
}

.results-wrapper {
  width: 100%;
}

.no-results {
  text-align: center;
}

.no-results .query {
  font-style: italic;
}
